@media (max-width: 700px) {
.mt-4.space-y-2.w-full.flex.flex-col {
    width: 90%;
    margin: 20px auto 0 auto;
}

}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .fade-in {
    animation: fadeIn 0.2s forwards;
  }
  