.custom-popup .mapboxgl-popup-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(20px) translateY(10px);
  background-color: black;
  color: white;
  font-size: 14px;
  font-weight:500;
  padding: 6px;
  margin-left: 0px;
  width: max-content;
  max-width: none;
  opacity: 0.7;
  white-space: normal;
  pointer-events: none !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none !important;
}

.mapboxgl-touch-pan-blocker {
  font-size: 0px !important;
}

.mapboxgl-scroll-zoom-blocker {
  font-size: 0px !important;
}

.mapboxgl-popup {
  max-width: none !important;
}

.custom-popup .mapboxgl-popup-tip {
  display: none; /* Hide the popup tip arrow */
}

@media (max-width: 1000px) {
  .mappp {
    height: 80vh !important;
    border-radius: 8px;
  }
  .day-selector {
    position: absolute;
    top: 40px;
    right: 50px;
    background: #fff;
    padding: 5px 3px;
    border-radius: 5px;
  }
  .mode-selector {
    top: 40px;
    position: absolute;
    left: 10px;
    background: #fff;
    padding: 5px 3px;
    border-radius: 5px;
  }
  .question-mark-button {
    position: absolute;
    bottom: calc(1rem + 30px);
    right: 1rem;
  }
}

@media (min-width: 1001px) {
  .mappp {
    height: calc(100vh - 43px) !important;
  }
  .day-selector {
    top: 10px;
    position: absolute;
    right: 50px;
    background: #fff;
    padding: 5px 3px;
    border-radius: 5px;
  }
  .mode-selector {
    top: 10px;
    position: absolute;
    left: 10px;
    background: #fff;
    padding: 5px 3px;
    border-radius: 5px;
  }
  .question-mark-button {
    position: absolute;
    bottom: 20px;
    left: 1rem;
    z-index: 998;
  }
}

.help-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.help-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff; /* Crisp white background */
  padding: 20px 20px; /* Increased padding for better spacing */
  border-radius: 8px; /* Slightly larger radius for softer corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  z-index: 1008;
  /* font-family: 'Arial', sans-serif; */
  font-weight:300;
  color: #333; /* Darker font color for better contrast */
  border: 1px solid #e0e0e0; /* Soft grey border */
}

