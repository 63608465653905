.calendar-container {
  position: relative;
  max-height: calc(100vh - 43px);
  min-height: calc(100vh - 43px);
  width: 52%;
  overflow-y: auto;
}

/* .schedule {
    transform: scale(1);
} */

.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}

.course-name {
  border-bottom: 1px solid white;
}

.header-and-calendar {
  margin: 0px 20px 5px 20px;
  /* border-radius: 5px; */
  box-shadow: 0px 1px 5px 1px rgb(22, 22, 22);
}

.online-section-message {
  border-top-left-radius: 5px; /* Round the top-left corner */
  border-top-right-radius: 5px;
}

@media (max-width: 1000px) {
  .calendar-container {
    border-top: 0.5px solid #a9a9a9;
    width: calc(100vw - 20px);
    max-height: 100%;
  }

}

@media (min-width: 1001px) {
  .calendar-container {
    border-left: 0.5px solid #a9a9a9;
    max-height: calc(100vh - 43px);
    min-height: calc(100vh - 43px);
    position: fixed;
    top: 43px;
    left: 48%;
    flex: 1;
    max-width: 52%;
  }
}

/* .MainLayout-flexRow.MainLayout-stickyElement.MainLayout-leftPanel.MainLayout-ordinaryLeftPanelBorder {
  max-width: 65px;
} */

.spinner {
  border: 8px solid #0021a5;
  border-radius: 50%;
  border-top: 8px solid #fa4616;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;

  /* Centering the spinner */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* This will keep the spinner centered */
  z-index: 1000;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes fadeInWave {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-wave {
  opacity: 0;
  animation: fadeInWave 0.5s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-text-in {
  opacity: 0;
  animation: 0.5s fadeIn 0.5s forwards;
}
