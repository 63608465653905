.model-plan {
  color: white;
  position: relative;
  border-collapse: collapse;
  /* transform: translateX(50%); */
}

.model-plan th, .model-plan td {
  border: 1px solid white; /* Add border to table cells */
  padding: 6px; /* Add some padding for better readability */
}

.model-plan tr:nth-child(even) {
  background-color: black;
}

.model-plan tr:nth-child(odd) {
  background-color: rgb(28, 28, 28);
}

.model-plan .thicker-border td {
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}

.table-container {
  margin: auto;
  overflow-y: auto;
  overflow-x: auto;
  width: auto;
  height: auto;
  position: relative;
}

@media (max-width: 1000px) {
  .model-plan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 80vh;
    /* overflow-y: auto; */
    position: relative;
  }
}

@media (min-width: 1001px) {
  .model-plan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: calc(100vh - 43px);
    width: 52vw;
    /* overflow-y: auto; */
    position: relative;
    padding: 0px 2px 16px 12px;
  }
}
