.header-container {
  position: fixed;
  top:0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-left: 0px;
  z-index: 1000;
}

.header {
  /* position: fixed;
  top: 0;
  left: 0; */
  width: 100%;
  background-color: rgb(0, 0, 0);
  color: white;
  height: 43px;
  text-align: right;
  font-size: large;
  z-index: 1000;
  border-bottom: 0.5px solid #a9a9a9;
  align-items: center;
  display: flex;
  padding: 0 10px; /* Added padding */
  justify-content: space-between;
}

.credits-container {
  /* margin-right: auto; */
  display: flex;
  align-items: center;
  white-space: nowrap;
}

@media (min-width: 965px ) {
  .button-container {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    justify-content: center;
  }
  .Button {
    background-color: #292929;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 5px 10px;
    min-width: 10vw;
    color: white;
    font-size: 20px;
    margin-top: 8px;
    cursor: pointer;
    transition: opacity 0.2s ease;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
  }
  .label {
    margin-left: 8px;
  }
}

@media (max-width: 964px) {
  .button-container {
    display: flex;
    width: 100%;
    border-bottom: 0.5px solid #a9a9a9;
    background-color: black;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .Button {
    background-color: #292929;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 16px;
    border-left: 1px solid #a9a9a9;
    border-right: 1px solid #a9a9a9;
    width: 100%; /* Ensure buttons take full width of their flex container */
  }
}

.icon-text-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0 5px;
}

.Button:first-child {
  margin-left: 0;
  border-left: none;
}

.Button:last-child {
  margin-right: 0;
  border-right: none;
}

.Button.grayed {
  opacity: 0.4;
}
.Button.show {
  opacity: 1;
}

.menu-button {
  z-index: 99999;
  transition: opacity 0.3s ease;
  min-width: 18px;
  margin-right: 20px;
}

.faded {
  opacity: 0.5;
}

.buyButton {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  color: #ffffff;
  background-color: #292929;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 0.4rem;
  font-size: 1rem;
  letter-spacing: 0.6px;
  box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5);
  font-family: cursive;
  /* position: fixed;
  bottom: 25px;
  left: 0px;
  z-index: 10001; */
  /* margin-left: 30px; */
  margin-top: 0.25rem;
}
.buyButton:hover,
.buyButton:active,
.buyButton:focus {
  /* text-decoration: underline; */
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5);
  opacity: 0.85;
  color: #ffffff;
}

.coffeeImage {
  height: 2vh;
  box-shadow: none;
  border: none;
  vertical-align: middle;
}

.coffeeButtonText {
  margin-left: 2px;
  margin-right: 2px;
  font-size: 0.8rem;
  vertical-align: middle;
}

.gatorEmoji {
  font-size: 0.8rem;
}