@media (max-width: 1000px) {
  .filtered-courses-container {
    max-height: calc(80vh - 8rem);
  }
}

@media (min-width: 1001px) {
  .filtered-courses-container {
    max-height: calc(100vh - 128px);
  }
}

@keyframes fadeInWave {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-wave {
  opacity: 0;
  animation: fadeInWave 0.5s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-text-in {
  opacity: 0;
  animation: fadeIn 0.1s forwards;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}